<template>
  <div class="g-box g-code-add-box" v-loading="loading">
    <div class="m-form-inline-mn" data-m="add">
      <el-form
        :model="ruleForm" class="demo-form-inline" size="small"
        :rules="rules" ref="ruleForm" label-width="180px"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <h2 class="m-tt" v-if="isModeAdd">新增字典</h2>
            <h2 class="m-tt" v-if="isModeEdit">字典编辑</h2>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="字典名称" prop="value">
              <el-input
                v-model="ruleForm.value" placeholder="请输入字典名称" :readonly="isModeInfo"
                maxlength="30"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="所属系统" prop="system">
              <el-select v-model="ruleForm.system" placeholder="请选择所属系统">
                <el-option
                  v-for="item in systemCodeList" :key="item.code" :label="item.value"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="字典代码" prop="code">
              <el-input
                v-model="ruleForm.code" placeholder="请输入字典代码" :readonly="isModeInfo"
                maxlength="30"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="20">
            <el-form-item label="字典说明" prop="description">
              <el-input
                type="textarea" :rows="2" v-model="ruleForm.description"
                placeholder="请输入字典说明" :readonly="isModeInfo" maxlength="60"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="m-btn-group">
      <el-button
        class="u-btn s-submit" size="small" type="primary"
        @click="submitForm('ruleForm')" v-if="!isModeInfo"
        :loading="submitLoading" :disabled="submitLoading"
      >
        提交
      </el-button>
      <el-button
        class="u-btn s-cancel" size="small" v-if="isModeInfo"
        @click="$router.go(-1)"
      >
        返回
      </el-button>
      <el-button
        class="u-btn s-cancel" size="small" v-else
        @click="returnPreviousPage"
      >
        返回
      </el-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import api from './../../../api'
import VueCookie from 'vue-cookie'

export default Vue.extend({
  name: 'code-add',
  components: {
  },
  directives: {},
  filters: {},
  props: {},
  data () {
    return {
      ruleForm: {
        code: '',
        value: '',
        description: '',
        system: ''
      },
      loading: true,
      submitLoading: false,
      rules: {
        value: [
          { required: true, message: '请输入字典名称', trigger: 'blur' },
          { pattern: /\S+/, message: '不能全为空格' }
        ],
        code: [
          { required: true, message: '请输入字典代码', trigger: 'blur' },
          //   { max: 15, message: '最多15位字符', trigger: 'blur' },
          { pattern: /\S+/, message: '不能全为空格' }
        ],
        system: [
          { required: true, message: '请选择所属系统', trigger: 'change' }
        ],
        description: []
      },
      systemCodeList: []
    }
  },
  computed: {
    isModeAdd () {
      return this.$route.name === 'code-add'
    },
    isModeEdit () {
      return this.$route.name === 'code-edit'
    },
    isModeInfo () {
      return this.$route.name === 'code-info'
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
    this.init()
  },
  beforeMount () {
  },
  mounted () {
  },
  activated () {
  },
  deactivated () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
    this.submitLoading = false
  },
  methods: {
    init () {
      if (this.isModeAdd) {
        this.loading = false
        // this.getAllOrgLevel()
      }
      if (this.isModeInfo) {
        this.getInfo(() => {
        })
      }
      if (this.isModeEdit) {
        this.getInfo(() => {
        })
      }
      this.getDict()
    },
    getInfo (callback = () => {}) {
      api.auth.postFindDictById({
        parkId: parseInt(VueCookie.get('parkId')),
        id: this.$route.params.id
      }).then(res => {
        this.ruleForm = res.data
      }).finally(() => {
        this.loading = false
      })
    },
    getDict () {
      api.auth.postDictList({
        parkId: '',
        code: 'SYSTEM'
      }).then(res => {
        this.systemCodeList = res.data
      })
    },
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.isModeAdd) {
            api.auth.postAddOrUpdateDict({
              ...this.ruleForm,
              parkId: parseInt(VueCookie.get('parkId'))
            }
            ).then(() => {
              this.$router.push('/authority-management/code/code-list')
            }).finally(() => {
              this.submitLoading = false
            })
          }
          if (this.isModeEdit) {
            api.auth.postAddOrUpdateDict({
              id: this.$route.params.id || '',
              ...this.ruleForm
            }).then(() => {
              this.$router.push('/authority-management/code/code-list')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    crop (res) {
      this.ruleForm.img = res.data
    }
  }
})
</script>

<style lang="scss" scoped>
  @import "./../../../assets/css/global-variable.scss";
  .g-code-add-box {
    position: relative;
    overflow: auto;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
  }
  .m-tt {
    margin-bottom: 30px;
    font-size: $fs-title;
    color: $fc-primary;
    font-weight: 500;
    line-height: 18px;
    text-indent: 10px;
    border-left: 4px solid $fc-theme;
    &.s-no-top {
      margin-top: 30px;
    }
  }
  .u-tree {
    float: left;
    min-width: 200px;
  }
</style>

<style>
</style>
